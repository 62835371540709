<template>
  <div class="my-5 text-center">
    <div class="row align-items-stretch">
      <div v-for="(navbar, index) in navbarData" :key="index++" class="col-4 p-0 m-0">
        <div class="shadow rounded m-1 py-3">
          <router-link
            :to="{ name: navbar.name }"
            :class="$route.name.includes(navbar.slug) ? 'active' : ''"
            class="py-xl-1 px-xl-2 text-decoration-none text-dark "
          >
            <i :class="`${navbar.icon}`" class="text-secondary"></i>
            <div>
              {{ navbar.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbar from "../../data/navbar/index.json";
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
      navbarData: navbar,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
