<template>
    <div>
        <MobileNavbarVue/>
    </div>
</template>
<script>
import MobileNavbarVue from '../../components/mobile/Navbar.vue'
export default {
    components:{
        MobileNavbarVue
    }
}
</script>